// auth_store.js
import axios from "axios";
import * as queries from "../graphql_hasura/queries";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword,
  signOut,
  onAuthStateChanged,
  onIdTokenChanged,
} from "firebase/auth";


async function setupCdnAccess(token) {
  try {
    
    const response = await fetch('https://cdn-signer.media.staging.elanco.onecup.ai/setCdnCookies', {
      method: 'POST',
      credentials: 'include', // Important for cookies
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
            },
      body: JSON.stringify({
        buckets: ['elanco-archive-thumbnails-lb'] // Request both buckets
      })
    });

    const result = await response.json();
    console.log('Cookie setting result:', result);
  } catch (error) {
    console.error('Error setting up CDN access:', error);
    throw error;
  }
}

// Initialize Firebase
const firebase_config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebase_config);
const auth = getAuth(app);

export const auth_module = {
  namespaced: true,

  state: () => ({
    user: null,
    jwt_token: null,
    loading: false,
    login_error: "",
    authorized: false,
    user_ddb: null,
    hasura_params: {
      url: process.env.VUE_APP_HASURA_ENDPOINT,
      headers: {
        Authorization: "",
        "Content-Type": "application/json",
      },
    },
  }),

  mutations: {
    set_user(state, user) {
      state.user = user;
      state.authorized = !!user;
    },
    set_jwt_token(state, token) {
      state.jwt_token = token;
    },
    set_login_error(state, error) {
      state.login_error = error;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_user_data(state, user_data) {
      state.user_ddb = user_data;
    },
    set_hasura_params(state, auth_token) {
      state.hasura_params = {
        url: process.env.VUE_APP_HASURA_ENDPOINT,
        headers: {
          Authorization: "Bearer " + auth_token,
          "Content-Type": "application/json",
        },
      };
    },
  },

  actions: {
    async login({ commit, dispatch }, { email, password }) {
      commit("set_loading", true);
      commit("set_login_error", "");

      try {
        const user_credential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = user_credential.user;

        // Get the Firebase JWT token
        const token = await user.getIdToken(true);
        await setupCdnAccess(token);
        // Set up authentication state
        commit("set_user", user);
        commit("set_jwt_token", token);
        commit("set_hasura_params", token);

        // Set up token refresh
        onIdTokenChanged(auth, async (user) => {
          if (user) {
            const new_token = await user.getIdToken(true);
            commit("set_jwt_token", new_token);
            commit("set_hasura_params", new_token);
          }
        });

        // Get additional user data if needed
        await dispatch("get_user_data", user.uid);

        return user;
      } catch (error) {
        let error_message = "An error occurred during login";

        switch (error.code) {
          case "auth/invalid-email":
            error_message = "Invalid email address";
            break;
          case "auth/user-disabled":
            error_message = "This account has been disabled";
            break;
          case "auth/user-not-found":
            error_message = "User not found";
            break;
          case "auth/wrong-password":
            error_message = "Invalid password";
            break;
          default:
            error_message = error.message;
        }

        commit("set_login_error", error_message);
        throw error;
      } finally {
        commit("set_loading", false);
      }
    },

    async logout({ commit }) {
      try {
        await signOut(auth);
        // Reset all auth related state
        commit("set_user", null);
        commit("set_jwt_token", null);
        commit("set_hasura_params", null);

        commit("set_user_data", null);

        console.clear();
        localStorage.clear();
        this.router.push("/login");
      } catch (error) {
        console.error("Logout error:", error);
        throw error;
      }
    },

    async send_password_reset({ commit }, email) {
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        commit("set_login_error", error.message);
        throw error;
      }
    },

    async confirm_password_reset({ commit }, { code, new_password }) {
      try {
        await confirmPasswordReset(auth, code, new_password);
      } catch (error) {
        commit("set_login_error", error.message);
        throw error;
      }
    },

    async update_password({ commit }, new_password) {
      try {
        const user = auth.currentUser;
        if (user) {
          await updatePassword(user, new_password);
        }
      } catch (error) {
        commit("set_login_error", error.message);
        throw error;
      }
    },

    // This action can be expanded to fetch additional user data from your backend
    async get_user_data({ commit, state }, user_id) {
      try {
        let customer = (
          await axios.post(
            state.hasura_params.url,
            {
              query: queries.customer_by_pk,
              variables: {
                id: user_id,
              },
            },
            {
              headers: state.hasura_params.headers,
            }
          )
        ).data.data.customer_by_pk;

        commit("set_user_data", customer);
      } catch (error) {
        commit("set_user_data", null);
        console.error("Error fetching user data:", error);
        throw error;
      }
    },

    async init_auth({ commit, dispatch }) {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const token = await user.getIdToken(true);
            commit("set_user", user);
            commit("set_jwt_token", token);
            commit("set_hasura_params", token);
            await dispatch("get_user_data", user.uid);
          } else {
            commit("set_user", null);
            commit("set_jwt_token", null);
            commit("set_hasura_params", null);
            commit("set_user_data", null);
          }
          resolve();
        });
      });
    },
  },

  getters: {
    is_authenticated: (state) => !!state.user,
    current_user: (state) => state.user,
    jwt_token: (state) => state.jwt_token,
    login_error: (state) => state.login_error,
    user_data: (state) => state.user_ddb,
    hasura_params: (state) => state.hasura_params,
  },
};
