import axios from "axios";

export const weather_module = {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    // current
    currentWeather: Object,
    currentTemp: "",
    minTemp: "",
    maxTemp: "",
    feelsLike: "",
    sunrise: "",
    sunset: "",
    pressure: "",
    humidity: "",
    wind: "",
    overcast: "",
    name: "",
    UV: "",
    visibility: "",
    wind_speed: "",
    wind_degree: "",
    wind_gust: "",
    clouds: "",
    latitude: "",
    longitude: "",
    hourlyHours: [],
    current: Object,
    daily: Object,
    hourly: Object,
  },
  mutations: {
    SET_WEATHER_DATA(state, payload) {
      // current

      //state.current = payload.current
      state.currentTemp = payload.main.temp;
      state.currentWeather = payload.weather[0].description;
      state.sunrise = new Date(payload.sys.sunrise * 1000).toLocaleString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
        }
      );
      state.sunset = new Date(payload.sys.sunset * 1000).toLocaleString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
        }
      );
      state.feelsLike = payload.main.feels_like + "°C";
      state.humidity = payload.main.humidity + "%";
      //state.UV = payload.current.uvi;
      state.clouds = payload.clouds.all + "%";
      state.visibility = payload.visibility / 1000 + " km";
      state.wind_speed = (payload.wind.speed * 3.6).toPrecision(2) + " km/h";
      state.wind_degree = payload.wind.deg + "°";
      // daily
      state.daily = payload.daily;
      // hourly
      state.hourly = payload.hourly;
    },
  },
  actions: {
    GET_WEATHER_DATA({ commit, state }, site_coord) {
      axios
        .get(
          "https://api.openweathermap.org/data/2.5/weather?lat=" +
            site_coord.latitude +
            "&lon=" +
            site_coord.longitude +
            "&exclude=minutely" +
            "&units=metric&appid=" +
            "9fffbcda9b424a299c3f2b549a4b8679"
        )
        .then((response) => {
          commit("SET_WEATHER_DATA", response.data);
          state.loading = false;
        });
    },
  },
  getters: {
    weather_getter(state) {
      return state;
    },
  },
};