import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/main_pages.vue"),
    },
    {
      path: "/animals",
      name: "animals",
      component: () => import("../views/livestock.vue"),
      props: true,
    },

    {
      path: "/account",
      name: "account",
      component: () => import("../views/user_settings.vue"),
      props: true,
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/login.vue"),
    },
    {
      path: "/notes",
      name: "notes",
      component: () => import("../views/notes.vue"),
    },
    {
      path: "/videos/:study",
      name: "videos",
      component: () => import("../views/videos.vue"),
      props: true,
    },

    {
      path: "/analytics/:study",
      name: "analytics",
      component: () => import("../views/analytics.vue"),
      props: true,
    },
    {
      path: "/cameras",
      name: "cameras",
      component: () => import("../views/cameras.vue"),
      props: true,
    },

    {
      path: "*",
      name: "not found",
      component: () => import("../views/404.vue"),
    },
  ],
});

router.beforeResolve(async (to, from, next) => {
  await store.dispatch("auth/init_auth");
  const auth_service = store.getters["auth/current_user"];
  if (!(to.name == "Login" || to.name == "register") && auth_service == null) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else next();
});

export default router;
