import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import { auth_module } from "./storejs_modules/auth_store.js";
import { weather_module } from "./storejs_modules/weather_store.js";
import { analytics_module } from "./storejs_modules/analytics_store.js";


import axios from "axios";
import * as queries from "./graphql_hasura/queries";

Auth.currentUserInfo;


Vue.use(Vuex);

//#endregion
// function sort_by_key_elanco_trial(array) {
//   return array.sort(function (a, b) {
//     var x =
//       a.Data.length && a.Data[0].ScalarValue
//         ? parseInt(a.Data[0].ScalarValue.split("-")[1])
//         : null;
//     var y =
//       b.Data.length && b.Data[0].ScalarValue
//         ? parseInt(b.Data[0].ScalarValue.split("-")[1])
//         : null;
//     if (x == null) {
//       x = "zzz";
//     }
//     if (y == null) {
//       y = "zzz";
//     }

//     return x < y ? -1 : x > y ? 1 : 0;
//   });
// }


const store = new Vuex.Store({
  modules: {
    weather: weather_module,
    auth: auth_module,
    analytics: analytics_module,
  },
  state: {
    cameras: [],
    studies: [],
    animals: [],

    video_cameras: [{ text: "No cameras found", value: null }],
    videos: [],
  },
  mutations: {



    set_cameras(state, payload) {
      state.cameras = payload;
    },
    set_studies(state, payload) {
      state.studies = payload;
    },
    set_animals(state, payload) {
      state.animals = payload;
    },

    set_video_cameras(state, payload) {
      state.video_cameras = payload;
    },
    set_videos(state, payload) {
      state.videos = payload;
    },
  },

  actions: {
    // DDB_GET_CAMERA_TRIALS_DOG_TOTAL({ commit, state }) {
    //   return new Promise((resolve) => {
    //     async function get_data(table) {
    //       var database_name = process.env.VUE_APP_TIMESTREAM_DB;
    //       var trial = [];

    //       var query = `
    //         SELECT 
    //           "Animal ID", count(bin(time, 1hour)) AS time
    //         FROM "${database_name}"."${table}"
    //         WHERE 
    //           time BETWEEN timestamp '2022-04-05 11:00:00'
    //             AND timestamp '2023-02-17 10:00:00'
    //           GROUP BY "Animal ID"
    //           ORDER BY time ASC
    //     `;
    //       var lambda_params = {
    //         FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
    //         InvocationType: "RequestResponse",

    //         Payload: JSON.stringify({
    //           auth_token: state.jwt_token,
    //           owner_claim: state.customer_id,
    //           timestream_query: query,
    //           timestream_next_token: null,
    //         }),
    //       };
    //       var command = new InvokeCommand(lambda_params);
    //       var data = await state.lambda_client.send(command);
    //       let return_data = binArrayToJson(data.Payload).timestream_response
    //         ? binArrayToJson(data.Payload).timestream_response.response
    //         : [];
    //       // return_data.shift()
    //       trial = sort_by_key_elanco_trial(return_data);
    //       return trial;
    //     }
    //     async function get_all_data() {
    //       var human = await get_data("annotation");
    //       var betsy = await get_data("scratching_1min");
    //       commit("SET_CAMERA_TRIALS_DOG_TOTAL", { human: human, betsy: betsy });
    //       resolve();
    //     }
    //     get_all_data();
    //   });
    // },
    // DDB_GET_TRIALS_DOG_TOTAL({ commit, state }, params) {
    //   return new Promise((resolve) => {
    //     async function get_data(table, dates) {
    //       var database_name = process.env.VUE_APP_TIMESTREAM_DB;
    //       var trial = [];
    //       let bin_size = !params.bin_size
    //         ? "time"
    //         : `bin(time, ${params.bin_size})`;
    //       for (const i in dates) {
    //         var query = `
    //         SELECT 
    //            ${bin_size} AS time, 
    //           Sum(CASE 
    //             WHEN Score > 0
    //             ${
    //               params.max_conf_cutoff && table != "annotation"
    //                 ? `AND "Maximum Confidence Score" > ${params.max_conf_cutoff}`
    //                 : ""
    //             }
    //             THEN Score
    //             ELSE 0
    //             END) AS Score
    //         FROM "${database_name}"."${table}"
    //         WHERE 
    //           time BETWEEN timestamp '${dates[i][0]}'
    //             AND timestamp '${dates[i][1]}'
    //           GROUP BY  ${bin_size}
    //           ORDER BY time ASC
    //     `;
    //         var lambda_params = {
    //           FunctionName:
    //             process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
    //           InvocationType: "RequestResponse",

    //           Payload: JSON.stringify({
    //             auth_token: state.jwt_token,
    //             owner_claim: state.customer_id,
    //             timestream_query: query,
    //             timestream_next_token: null,
    //           }),
    //         };
    //         var command = new InvokeCommand(lambda_params);
    //         var data = await state.lambda_client.send(command);
    //         let return_data = binArrayToJson(data.Payload).timestream_response
    //           ? binArrayToJson(data.Payload).timestream_response.response
    //           : [];
    //         // return_data.shift()
    //         trial[i] = sort_by_key_elanco_trial(return_data);
    //       }
    //       return trial;
    //     }
    //     async function get_all_data() {
    //       var t1human = await get_data("annotation", [
    //         ["2022-04-05 11:00:00", "2022-05-10 10:00:00"],
    //         ["2022-05-10 11:00:00", "2022-06-28 10:00:00"],
    //         ["2022-06-28 11:00:00", "2022-07-30 10:00:00"],
    //       ]);
    //       var t1betsy = await get_data("scratching_1min", [
    //         ["2022-04-05 11:00:00", "2022-05-10 10:00:00"],
    //         ["2022-05-10 11:00:00", "2022-06-28 10:00:00"],
    //         ["2022-06-28 11:00:00", "2022-07-30 10:00:00"],
    //       ]);
    //       var t2betsy = await get_data("scratching_1min", [
    //         ["2022-11-21 11:00:00", "2022-12-06 10:00:00"],
    //         ["2022-12-05 11:00:00", "2023-01-17 10:00:00"],
    //         ["2023-01-17 11:00:00", "2023-02-17 10:00:00"],
    //       ]);
    //       commit("SET_HOME_SCRATCHING_GRAPHS", {
    //         t1human: t1human,
    //         t1betsy: t1betsy,
    //         t2betsy: t2betsy,
    //       });
    //       resolve();
    //     }
    //     get_all_data();
    //   });
    // },


    async ddb_get_cameras({ commit, state }) {
      try {
        let camera = (
          await axios.post(
            state.auth.hasura_params.url,
            {
              query: queries.camera,
              variables: {
                where: { deleted_at: { _is_null: true } },
              },
            },
            {
              headers: state.auth.hasura_params.headers,
            }
          )
        ).data.data.camera;
        commit("set_cameras", camera);
      } catch (error) {
        commit("set_cameras", null);
        console.error("Error fetching camera data:", error);
        throw error;
      }
    },

    async ddb_get_studies({ commit, state }) {
      try {
        let study = (
          await axios.post(
            state.auth.hasura_params.url,
            {
              query: queries.study,
              variables: {
                where: { deleted_at: { _is_null: true } },
              },
            },
            {
              headers: state.auth.hasura_params.headers,
            }
          )
        ).data.data.study;
        commit("set_studies", study);
      } catch (error) {
        commit("set_studies", null);
        console.error("Error fetching study data:", error);
        throw error;
      }
    },

    async ddb_get_animals({ commit, state }) {
      try {
        let animal = (
          await axios.post(
            state.auth.hasura_params.url,
            {
              query: queries.animal,
              variables: {
                where: { deleted_at: { _is_null: true } },
              },
            },
            {
              headers: state.auth.hasura_params.headers,
            }
          )
        ).data.data.animal;
        commit("set_animals", animal);
      } catch (error) {
        commit("set_animals", null);
        console.error("Error fetching animal data:", error);
        throw error;
      }
    },

    async ddb_get_video_cameras({ commit, state }, study) {
      try {
        let camera = (
          await axios.post(
            state.auth.hasura_params.url,
            {
              query: queries.camera,
              variables: {
                where: {
                  deleted_at: { _is_null: true },
                  id: { _regex: study },
                },
              },
            },
            {
              headers: state.auth.hasura_params.headers,
            }
          )
        ).data.data.camera;

        let camera_items = [];
        for (const i in camera) {
          camera_items.push({ text: camera[i].name, value: camera[i].id });
        }
        commit("set_video_cameras", camera_items);
      } catch (error) {
        commit("set_video_cameras", [
          { text: "No cameras found", value: null },
        ]);
        console.error("Error fetching camera data:", error);
        throw error;
      }
    },

    async ddb_get_videos({ commit, state }, params) {
      try {
        let where = {
          deleted_at: { _is_null: true },
          camera_id: { _regex: params.study },
          datetime: { _gte: params.dates[0], _lte: params.dates[1] },
        }
        if (params.selected_bookmark) {
          where.bookmarked = { _eq: true };
        }
        if (params.cameras) {
          where.camera_id = {_eq: params.cameras};
        }
        let video = (
          await axios.post(
            state.auth.hasura_params.url,
            {
              query: queries.video,
              variables: {
                where: where,
                order_by: { datetime: params.sort_order }
              },
            },
            {
              headers: state.auth.hasura_params.headers,
            }
          )
        ).data.data.video;
        commit("set_videos", video);
      } catch (error) {
        commit("set_videos", []);
        console.error("Error fetching camera data:", error);
        throw error;
      }
    },
  },

  getters: {

    cameras(state) {
      return state.cameras;
    },
    studies(state) {
      return state.studies;
    },
    animals(state) {
      return state.animals;
    },

    video_cameras(state) {
      return state.video_cameras;
    },
    videos(state) {
      return state.videos;
    },
  },
});

export default store;
