import * as queries from "../graphql_hasura/queries";
import axios from "axios";


function elanco_arr_to_obj(array) {
  var elanco_obj = {};
    for (const i in array) {
      elanco_obj[array[i].animal_id] = array[i];
    }
  return elanco_obj;
}

export const analytics_module = {
  namespaced: true,

  state: () => ({
    analytics: { human: [], betsy: [] },
  }),

  mutations: {
    set_analytics(state, payload) {
      state.analytics = payload;
    },
  },

  actions: {
    ddb_get_analytics({ rootGetters, commit }, params) {
      return new Promise((resolve) => {
        async function get_data(query) {
          var trial = [];
  
          for (const i in params.dates) {
            const hasuraParams = rootGetters['auth/hasura_params']
            let args = { date_start: params.dates[i].dates[0], date_end: params.dates[i].dates[1], bin_size_minute: params.bin_size };
            if (query === "trial_analytics_ai_old") { 
              args['max_conf_score'] = params.max_conf_cutoff;
            }
            let data = (
              await axios.post(
                hasuraParams.url,
                {
                  query: queries[query],
                  variables: {
                    args: args,
                  },
                },
                {
                  headers: hasuraParams.headers,
                }
              )
            ).data;
            console.log(data)
            trial[i] = elanco_arr_to_obj(data.data[query]);
          }
          return trial;
        }

        async function get_all_data() {
          var betsy = [];
          var human = [];
          if (!params.new) {
            betsy = await get_data("trial_analytics_ai_old");
            human = await get_data("trial_analytics_human_old");
          } else { 
            betsy = await get_data("trial_analytics_ai_new");
          }
          commit("set_analytics", { human: human, betsy: betsy });
          resolve();
        }
        get_all_data();
      });
    },
  },

  getters: {
    analytics: (state) => state.analytics,
  },
};
