/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const customer_by_pk = /* GraphQL */ `
  query customer_by_pk($id: String!) {
    customer_by_pk(id: $id) {
      active
      type
      preferred_name
      phone_work
      phone_home
      phone_fax
      phone_cell
      last_name
      id
      first_name
      company_occupation
    }
  }
`;

export const study = /* GraphQL */ `
  query study($where: study_bool_exp) {
    study(where: $where, order_by: { start: asc }) {
      trials
      start
      name
      id
      has_human_annotation
      end
      new
      description
      deleted_at
      created_at
    }
  }
`;

export const camera = /* GraphQL */ `
  query camera($where: camera_bool_exp) {
    camera(where: $where) {
      updated_at
      study_id
      name
      model
      manufacturer
      id
      deleted_at
      created_at
    }
  }
`;

export const animal = /* GraphQL */ `
  query animal($where: animal_bool_exp) {
    animal(where: $where) {
      updated_at
      species
      sex
      name
      id
      dob
      deleted_at
      created_at
      color
      classification
      cod
    }
  }
`;

export const video = /* GraphQL */ `
  query video($where: video_bool_exp, $order_by: [video_order_by!]) {
    video(where: $where, order_by: $order_by) {
      width
      video_bitrate_kbps
      updated_at
      size_mb
      processing_start
      processing_server
      processing_end
      id
      height
      fps
      duration_seconds
      deleted_at
      datetime
      created_at
      camera_id
      bookmarked
    }
  }
`;

// ANALYTICS

export const trial_analytics_ai_new = /* GraphQL */ `
  query trial_analytics_ai_new($args: trial_analytics_ai_new_arguments!) {
    trial_analytics_ai_new(args: $args) {
      animal_id
      scores
      times
    }
  }
`;

export const trial_analytics_ai_old = /* GraphQL */ `
  query trial_analytics_ai_old($args: trial_analytics_ai_old_arguments!) {
    trial_analytics_ai_old(args: $args) {
      animal_id
      scores
      times
    }
  }
`;
export const trial_analytics_human_old = /* GraphQL */ `
  query trial_analytics_human_old($args: trial_analytics_human_old_arguments!) {
    trial_analytics_human_old(args: $args) {
      animal_id
      scores
      times
    }
  }
`;
